import React, {Component} from 'react';
import {HashRouter, Redirect, Route, Switch} from 'react-router-dom';
import Loadable from 'react-loadable';
import 'inoport-frontend/src/App.scss';
import './App.css';
import PropTypes from 'prop-types';
import 'react-toastify/dist/ReactToastify.css';

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Containers
const SeyetraLayout = Loadable({
    loader: () => import('./containers/SeyetraLayout'),
    loading
});

// Pages
const Login = Loadable({
    loader: () => import('../node_modules/inoport-frontend/src/views/CoreUI/Pages/Login'),
    loading
});

const PasswordExpired = Loadable({
    loader: () => import('../node_modules/inoport-frontend/src/views/PasswordExpired/PasswordExpired'),
    loading
});

const Activation = Loadable({
    loader: () => import('../node_modules/inoport-frontend/src/views/Activation/Activation'),
    loading
});

class App extends Component {

    static contextTypes = {
        router: PropTypes.object
    };

    render() {

        /**
         * If the environment is 'development', code changes will not lead to a redirect to the login page.
         * Login is still needed for a valid JSESSIONID.
         //  **/
        function PrivateRoute({component: Component, authed, ...rest}) {
            return (
                <Route {...rest} render={props => (
                    localStorage.getItem('authStatus')
                        ? <Component {...props} />
                        : <Redirect to={{pathname: '/login', state: {from: props.location}}}/>
                )}/>
            );
        }

        return (
            <>
                <HashRouter>
                    <Switch>
                        <Route exact path="/login" name="Login Page" component={Login}/>
                        <Route exact path="/expired" name="Password zurücksetzen" component={PasswordExpired}/>
                        <Route exact path="/activateprofile" name="Benutzerkonto aktivieren" component={Activation}/>
                        <PrivateRoute path='/' component={SeyetraLayout}/>
                    </Switch>
                </HashRouter>
            </>
        );
    }
}

export default App;

