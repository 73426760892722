// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../../node_modules/css-loader/dist/cjs.js!./imgIcon/Dashboard/Dashboard.css");
var ___CSS_LOADER_AT_RULE_IMPORT_1___ = require("-!../../../../node_modules/css-loader/dist/cjs.js!./imgIcon/printer/printer.css");
var ___CSS_LOADER_AT_RULE_IMPORT_2___ = require("-!../../../../node_modules/css-loader/dist/cjs.js!./imgIcon/trash/trash.css");
var ___CSS_LOADER_AT_RULE_IMPORT_3___ = require("-!../../../../node_modules/css-loader/dist/cjs.js!./imgIcon/refresh/refresh.css");
var ___CSS_LOADER_AT_RULE_IMPORT_4___ = require("-!../../../../node_modules/css-loader/dist/cjs.js!./imgIcon/plus/plus.css");
var ___CSS_LOADER_AT_RULE_IMPORT_5___ = require("-!../../../../node_modules/css-loader/dist/cjs.js!./imgIcon/comment/comment.css");
var ___CSS_LOADER_AT_RULE_IMPORT_6___ = require("-!../../../../node_modules/css-loader/dist/cjs.js!./imgIcon/clock/clock.css");
var ___CSS_LOADER_AT_RULE_IMPORT_7___ = require("-!../../../../node_modules/css-loader/dist/cjs.js!./imgIcon/rightArrow/rightArrow.css");
var ___CSS_LOADER_AT_RULE_IMPORT_8___ = require("-!../../../../node_modules/css-loader/dist/cjs.js!./imgIcon/desktop/desktop.css");
var ___CSS_LOADER_AT_RULE_IMPORT_9___ = require("-!../../../../node_modules/css-loader/dist/cjs.js!./imgIcon/warning/warning.css");
var ___CSS_LOADER_AT_RULE_IMPORT_10___ = require("-!../../../../node_modules/css-loader/dist/cjs.js!./imgIcon/caretDown/caretDown.css");
var ___CSS_LOADER_AT_RULE_IMPORT_11___ = require("-!../../../../node_modules/css-loader/dist/cjs.js!./imgIcon/check/check.css");
var ___CSS_LOADER_AT_RULE_IMPORT_12___ = require("-!../../../../node_modules/css-loader/dist/cjs.js!./imgIcon/archive/archive.css");
var ___CSS_LOADER_AT_RULE_IMPORT_13___ = require("-!../../../../node_modules/css-loader/dist/cjs.js!./imgIcon/unlock/unlock.css");
var ___CSS_LOADER_AT_RULE_IMPORT_14___ = require("-!../../../../node_modules/css-loader/dist/cjs.js!./imgIcon/lock/lock.css");
var ___CSS_LOADER_AT_RULE_IMPORT_15___ = require("-!../../../../node_modules/css-loader/dist/cjs.js!./imgIcon/pieChart/pieChart.css");
var ___CSS_LOADER_AT_RULE_IMPORT_16___ = require("-!../../../../node_modules/css-loader/dist/cjs.js!./imgIcon/circle/circle.css");
var ___CSS_LOADER_AT_RULE_IMPORT_17___ = require("-!../../../../node_modules/css-loader/dist/cjs.js!./imgIcon/barChart/barChart.css");
var ___CSS_LOADER_AT_RULE_IMPORT_18___ = require("-!../../../../node_modules/css-loader/dist/cjs.js!./imgIcon/list/list.css");
var ___CSS_LOADER_AT_RULE_IMPORT_19___ = require("-!../../../../node_modules/css-loader/dist/cjs.js!./imgIcon/edit/edit.css");
var ___CSS_LOADER_AT_RULE_IMPORT_20___ = require("-!../../../../node_modules/css-loader/dist/cjs.js!./imgIcon/longArrowRight/longArrowRight.css");
var ___CSS_LOADER_AT_RULE_IMPORT_21___ = require("-!../../../../node_modules/css-loader/dist/cjs.js!./imgIcon/windowMaximize/windowMaximize.css");
var ___CSS_LOADER_AT_RULE_IMPORT_22___ = require("-!../../../../node_modules/css-loader/dist/cjs.js!./imgIcon/userCircle/userCircle.css");
var ___CSS_LOADER_AT_RULE_IMPORT_23___ = require("-!../../../../node_modules/css-loader/dist/cjs.js!./imgIcon/upload/upload.css");
var ___CSS_LOADER_AT_RULE_IMPORT_24___ = require("-!../../../../node_modules/css-loader/dist/cjs.js!./imgIcon/home/home.css");
var ___CSS_LOADER_AT_RULE_IMPORT_25___ = require("-!../../../../node_modules/css-loader/dist/cjs.js!./imgIcon/truck/truck.css");
var ___CSS_LOADER_AT_RULE_IMPORT_26___ = require("-!../../../../node_modules/css-loader/dist/cjs.js!./imgIcon/fileO/fileO.css");
var ___CSS_LOADER_AT_RULE_IMPORT_27___ = require("-!../../../../node_modules/css-loader/dist/cjs.js!./imgIcon/folder/folder.css");
var ___CSS_LOADER_AT_RULE_IMPORT_28___ = require("-!../../../../node_modules/css-loader/dist/cjs.js!./imgIcon/bus/bus.css");
var ___CSS_LOADER_AT_RULE_IMPORT_29___ = require("-!../../../../node_modules/css-loader/dist/cjs.js!./imgIcon/cubes/cubes.css");
var ___CSS_LOADER_AT_RULE_IMPORT_30___ = require("-!../../../../node_modules/css-loader/dist/cjs.js!./imgIcon/cube/cube.css");
var ___CSS_LOADER_AT_RULE_IMPORT_31___ = require("-!../../../../node_modules/css-loader/dist/cjs.js!./imgIcon/calendar/calendar.css");
var ___CSS_LOADER_AT_RULE_IMPORT_32___ = require("-!../../../../node_modules/css-loader/dist/cjs.js!./imgIcon/barcode/barcode.css");
var ___CSS_LOADER_AT_RULE_IMPORT_33___ = require("-!../../../../node_modules/css-loader/dist/cjs.js!./imgIcon/rotateLeft/rotateLeft.css");
var ___CSS_LOADER_AT_RULE_IMPORT_34___ = require("-!../../../../node_modules/css-loader/dist/cjs.js!./imgIcon/save/save.css");
var ___CSS_LOADER_AT_RULE_IMPORT_35___ = require("-!../../../../node_modules/css-loader/dist/cjs.js!./imgIcon/history/history.css");
var ___CSS_LOADER_AT_RULE_IMPORT_36___ = require("-!../../../../node_modules/css-loader/dist/cjs.js!./imgIcon/eye/eye.css");
var ___CSS_LOADER_AT_RULE_IMPORT_37___ = require("-!../../../../node_modules/css-loader/dist/cjs.js!./imgIcon/eyeSlash/eyeSlash.css");
var ___CSS_LOADER_AT_RULE_IMPORT_38___ = require("-!../../../../node_modules/css-loader/dist/cjs.js!./imgIcon/step-forward/stepForwardSolid.css");
var ___CSS_LOADER_AT_RULE_IMPORT_39___ = require("-!../../../../node_modules/css-loader/dist/cjs.js!./imgIcon/select/select.css");
var ___CSS_LOADER_AT_RULE_IMPORT_40___ = require("-!../../../../node_modules/css-loader/dist/cjs.js!./imgIcon/arrowDown/arrowDownGray.css");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_2___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_3___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_4___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_5___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_6___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_7___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_8___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_9___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_10___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_11___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_12___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_13___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_14___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_15___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_16___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_17___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_18___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_19___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_20___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_21___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_22___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_23___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_24___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_25___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_26___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_27___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_28___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_29___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_30___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_31___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_32___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_33___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_34___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_35___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_36___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_37___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_38___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_39___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_40___);
// Module
exports.push([module.id, ".sideNavIcon, .buttonIcon, .textIcon, .arrowsIcon, .bigIcon, .inputIcon, .cardIcon, .labelIcon, .yellowIcon {\n    display: inline-block;\n    position: relative;\n    height: 1rem;\n    width: 1rem;\n}\n\n.bigIcon {\n    height: 2rem;\n}\n\n.yellowIcon {\n    height: 3rem;\n    width: 3rem;\n}\n\n.arrowsIcon {\n    width: 2rem;\n}\n\n.sideNavIcon::before, .buttonIcon::before, .textIcon::before, .arrowsIcon::before, .bigIcon::before, .inputIcon::before, .cardIcon::before, .labelIcon::before, .yellowIcon::before {\n    content: \"\";\n    color: transparent;\n    display: inline-block;\n    width: 17px;\n    height: 21px;\n    position: absolute;\n    top: 0;\n    left: 0;\n    background-repeat: no-repeat;\n    background-position: center;\n    background-size: 15px;\n    transition: .1s;\n}\n\n\n.withText {\n    margin-right: 5px;\n}\n", ""]);
// Exports
module.exports = exports;
