// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./checkWhite.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "/*.check::before {*/\n/*    content: \"\";*/\n/*    color: transparent;*/\n/*    display: inline-block;*/\n/*    width: 17px;*/\n/*    height: 24px;*/\n/*    position: absolute;*/\n/*    top: 0;*/\n/*    left: 0;*/\n/*    background-repeat: no-repeat;*/\n/*    background-position: center;*/\n/*    background-size: 15px;*/\n/*    transition: .1s;*/\n/*}*/\n\n/*.sideNavIcon.archive::before {*/\n/*    background-image: url(\"./archiveGray.png\");*/\n/*}*/\n\n/*.nav-link.active .sideNavIcon.archive::before {*/\n/*    background-image: url(\"./archiveblue.png\");*/\n/*}*/\n\n/*.nav-item:hover .sideNavIcon.archive::before {*/\n/*    background-image: url(\"./archiveWhite.png\");*/\n/*}*/\n\n/*.buttonIcon.withText.archive {*/\n/*    margin-right: 5px;*/\n/*}*/\n\n/*.buttonIcon.archive::before {*/\n/*    background-image: url(\"./archiveWhite.png\");*/\n/*    background-size: 13px;*/\n/*}*/\n\n/*.textIcon.archive {*/\n/*    margin-right: 5px;*/\n/*}*/\n\n.textIcon.check::before {\n    margin-right: 5px;\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    background-size: 10px;\n}\n\n\n.bigIcon.check::before {\n    width: 40px;\n    height: 40px;\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    background-size: 40px;\n}\n", ""]);
// Exports
module.exports = exports;
