// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./warningWhite.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "/*.warning::before {*/\n/*    content: \"\";*/\n/*    color: transparent;*/\n/*    display: inline-block;*/\n/*    width: 17px;*/\n/*    height: 24px;*/\n/*    position: absolute;*/\n/*    top: 0;*/\n/*    left: 0;*/\n/*    background-repeat: no-repeat;*/\n/*    background-position: center;*/\n/*    background-size: 15px;*/\n/*    transition: .1s;*/\n/*}*/\n\n/*.sideNavIcon.warning::before {*/\n/*    background-image: url(\"./warningGray.png\");*/\n/*}*/\n\n/*.nav-link.active .sideNavIcon.warning::before {*/\n/*    background-image: url(\"./warningblue.png\");*/\n/*}*/\n\n/*.nav-item:hover .sideNavIcon.warning::before {*/\n/*    background-image: url(\"./warningWhite.png\");*/\n/*}*/\n\n/*.buttonIcon.withText.warning {*/\n/*    margin-right: 5px;*/\n/*}*/\n\n/*.buttonIcon.warning::before {*/\n/*    background-image: url(\"./warningWhite.png\");*/\n/*    background-size: 13px;*/\n/*}*/\n\n/*.textIcon.warning {*/\n/*    margin-right: 5px;*/\n/*}*/\n\n.textIcon.warning::before {\n    margin-right: 5px;\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    background-size: 10px;\n}\n\n\n.bigIcon.warning::before {\n    width: 40px;\n    height: 40px;\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    background-size: 40px;\n}\n\n.yellowIcon.warning::before {\n    background-color: #ffc107;\n    border-radius: 50%;\n    width: 45px;\n    height: 45px;\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    background-repeat: no-repeat;\n    background-position: 8px 5px;\n    background-size: 30px;\n}\n", ""]);
// Exports
module.exports = exports;
