import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import oldI18n from 'inoport-frontend/src/Translations/i18n';
import translationEN from './Language/en/translation.json';
import translationDE from './Language/de/translation.json';
import {inoportTranslationDE} from 'inoport-frontend/src/Translations/Language/de/translation.js';
import {inoportTranslationEN} from 'inoport-frontend/src/Translations/Language/en/translation.js';

// the translations
const resources = {
    en: {
        translation: {...inoportTranslationEN, ...translationEN}
    },
    de: {
        translation: {...translationDE, ...inoportTranslationDE}
    }
};
i18n
    .use(oldI18n) // passes i18n down to react-i18next
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: 'de',

        // keySeparator: '.', // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;
