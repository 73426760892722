// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./desktopGray.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./desktopBlue.png");
var ___CSS_LOADER_URL_IMPORT_2___ = require("./desktopWhite.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
exports.push([module.id, "/*.desktop::before {*/\n/*    content: \"\";*/\n/*    color: transparent;*/\n/*    display: inline-block;*/\n/*    width: 17px;*/\n/*    height: 24px;*/\n/*    position: absolute;*/\n/*    top: 0;*/\n/*    left: 0;*/\n/*    background-repeat: no-repeat;*/\n/*    background-position: center;*/\n/*    background-size: 15px;*/\n/*    transition: .1s;*/\n/*}*/\n\n.sideNavIcon.desktop::before {\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n\n.nav-link.active .sideNavIcon.desktop::before {\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n}\n\n.nav-item:hover .sideNavIcon.desktop::before {\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n}\n\n.buttonIcon.withText.desktop {\n    margin-right: 5px;\n}\n\n.buttonIcon.desktop::before {\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n    background-size: 13px;\n}\n\n.textIcon.desktop {\n    margin-right: 5px;\n}\n\n.textIcon.desktop::before {\n    margin-right: 5px;\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n    background-size: 10px;\n}\n", ""]);
// Exports
module.exports = exports;
